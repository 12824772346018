import React, { useState, useEffect } from 'react';
import { Phone, CheckCircle2, ChevronRight, Mail, MapPin } from 'lucide-react';
import { translations } from './translations';
import { PromoSticker, PromoBanner } from './components/PromoElements';

export default function App() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const [lang, setLang] = useState('ro');

  const t = translations[lang];

  useEffect(() => {
    setIsAnimated(true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phoneNumber) {
      try {
        const response = await fetch('/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phoneNumber }),
        });

        if (response.ok) {
          setShowSuccess(true);
          setTimeout(() => setShowSuccess(false), 3000);
          setPhoneNumber('');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };

  return (
    <div className="relative w-full min-h-screen bg-white overflow-x-hidden">
      {/* Nav */}
      <nav className="fixed w-full z-50 bg-white/90 backdrop-blur-lg shadow-md">
        <div className="max-w-7xl mx-auto px-4 md:px-6 h-20 flex items-center justify-between">
          <div className="flex items-center gap-2 md:gap-3">
            <div className="relative w-10 md:w-12 h-10 md:h-12 bg-gradient-to-br from-blue-600 to-indigo-600 rounded-xl flex items-center justify-center transform rotate-12">
              <div className="absolute inset-0.5 bg-white rounded-lg rotate-6" />
              <span className="relative text-xl md:text-2xl font-bold text-blue-600 rotate-[-18deg]">RDM</span>
            </div>
            <div>
              <div className="text-lg md:text-xl font-bold text-blue-600">FINANCE SOLUTIONS</div>
            </div>
          </div>

          <div className="flex items-center gap-4 md:gap-6">
            <div className="hidden md:flex gap-6 md:gap-8">
              <a href="#" className="text-gray-600 hover:text-blue-600 whitespace-nowrap">{t.nav.about}</a>
              <a href="#" className="text-gray-600 hover:text-blue-600 whitespace-nowrap">{t.nav.services}</a>
              <a href="#" className="text-gray-600 hover:text-blue-600 whitespace-nowrap">{t.nav.calculator}</a>
              <a href="#" className="text-gray-600 hover:text-blue-600 whitespace-nowrap">{t.nav.contact}</a>
            </div>

            <div className="flex items-center gap-4 border-l pl-4 md:pl-6">
              <button
                onClick={() => setLang('ro')}
                className={`text-sm font-medium ${lang === 'ro' ? 'text-blue-600' : 'text-gray-500'}`}
              >
                RO
              </button>
              <button
                onClick={() => setLang('ru')}
                className={`text-sm font-medium ${lang === 'ru' ? 'text-blue-600' : 'text-gray-500'}`}
              >
                RU
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Promo Banner */}
      <PromoBanner lang={lang} />

      {/* Hero Section */}
      <section className="relative w-full min-h-screen pt-44 pb-24 md:pb-32 flex items-center justify-center bg-gradient-to-br from-blue-50 via-indigo-50 to-white">
        {/* Promo Sticker */}
        <PromoSticker lang={lang} />

        <div className="absolute inset-0">
          <div 
            className={`absolute w-96 h-96 bg-blue-200/20 rounded-full -left-48 top-1/4 blur-3xl transition-all duration-1000 ${
              isAnimated ? 'opacity-100 translate-x-12' : 'opacity-0 -translate-x-12'
            }`}
          />
          <div 
            className={`absolute w-96 h-96 bg-indigo-200/20 rounded-full -right-48 top-1/2 blur-3xl transition-all duration-1000 delay-300 ${
              isAnimated ? 'opacity-100 -translate-x-12' : 'opacity-0 translate-x-12'
            }`}
          />
        </div>

        <div className="relative w-full max-w-5xl mx-auto px-4 md:px-6 text-center">
          <div className={`space-y-6 md:space-y-8 transition-all duration-1000 ${
            isAnimated ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'
          }`}>
            <div className="inline-block">
              <div className="bg-blue-100 text-blue-600 px-4 md:px-6 py-2 rounded-full text-base md:text-lg font-medium">
                {t.hero.badge}
              </div>
            </div>

            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 leading-tight">
              {t.hero.title}
              <span className="bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent block mt-2">
                {t.hero.titleHighlight}
              </span>
            </h1>

            <p className="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto">
              {t.hero.description}
            </p>

            {/* Contact Form */}
            <form onSubmit={handleSubmit} className="relative max-w-xl mx-auto">
              <div className="flex flex-col md:flex-row items-center gap-4 justify-center">
                <div className="w-full md:w-auto flex-1 max-w-md">
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder={t.hero.phonePlaceholder}
                    className="w-full px-4 md:px-6 py-3 md:py-4 rounded-full bg-white/80 backdrop-blur-md shadow-xl focus:ring-2 focus:ring-blue-500 text-center text-base md:text-lg"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full md:w-auto bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-6 md:px-8 py-3 md:py-4 rounded-full font-semibold hover:from-blue-700 hover:to-indigo-700 transition-all shadow-xl hover:shadow-blue-200/50 flex items-center justify-center gap-2 group whitespace-nowrap"
                >
                  <span>{t.hero.callButton}</span>
                  <Phone className="w-5 h-5 group-hover:rotate-12 transition-transform" />
                </button>
              </div>
              {showSuccess && (
                <div className="absolute -bottom-16 left-0 right-0 text-center">
                  <div className="inline-flex items-center gap-2 text-green-600 bg-green-50 px-4 py-2 rounded-full">
                    <CheckCircle2 className="w-5 h-5" />
                    <span>{t.hero.successMessage}</span>
                  </div>
                </div>
              )}
            </form>

            {/* Benefits */}
            <div className="grid md:grid-cols-3 gap-4 md:gap-6 mt-16 mb-8 md:mb-12">
              {t.benefits.map((benefit, i) => (
                <div 
                  key={i}
                  className={`bg-white/80 backdrop-blur-md p-3 md:p-4 rounded-xl shadow-lg flex items-center gap-3 transition-all duration-700 delay-${i * 200} ${
                    isAnimated ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
                  }`}
                >
                  <CheckCircle2 className="w-6 h-6 text-green-500 flex-shrink-0" />
                  <span className="text-gray-700 text-sm md:text-base">{benefit}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="relative w-full bg-gray-900 text-gray-300 py-8 md:py-12">
        <div className="max-w-7xl mx-auto px-4 md:px-6">
          <div className="grid md:grid-cols-3 gap-8 md:gap-12">
            {/* Company */}
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <div className="relative w-10 md:w-12 h-10 md:h-12 bg-gradient-to-br from-blue-600 to-indigo-600 rounded-xl flex items-center justify-center transform rotate-12">
                  <div className="absolute inset-0.5 bg-white rounded-lg rotate-6" />
                  <span className="relative text-xl md:text-2xl font-bold text-blue-600 rotate-[-18deg]">RDM</span>
                </div>
                <div className="text-lg md:text-xl font-bold text-white">FINANCE SOLUTIONS</div>
              </div>
            </div>

            {/* Contact */}
            <div className="space-y-4">
              <div className="space-y-3">
                <div className="flex gap-3 text-gray-400">
                  <MapPin className="w-5 h-5 text-blue-400 flex-shrink-0" />
                  <span className="text-sm md:text-base">{t.footer.address}</span>
                </div>
                <a href={`tel:${t.footer.phone}`} className="flex gap-3 text-gray-400 hover:text-blue-400">
                  <Phone className="w-5 h-5" />
                  <span className="text-sm md:text-base">{t.footer.phone}</span>
                </a>
                <a href={`mailto:${t.footer.email}`} className="flex gap-3 text-gray-400 hover:text-blue-400">
                  <Mail className="w-5 h-5" />
                  <span className="text-sm md:text-base">{t.footer.email}</span>
                </a>
              </div>
            </div>

            {/* Links */}
            <div className="space-y-3">
              {t.footer.links.map(link => (
                <a key={link} href="#" className="flex items-center gap-2 text-gray-400 hover:text-blue-400">
                  <ChevronRight className="w-4 h-4" />
                  <span className="text-sm md:text-base">{link}</span>
                </a>
              ))}
            </div>
          </div>

          <div className="border-t border-gray-800 mt-8 md:mt-12 pt-6 md:pt-8">
            <div className="text-center text-gray-400 text-xs md:text-sm">
              {t.footer.rights}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}