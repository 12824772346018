// translations.js
export const translations = {
  ro: {
    nav: {
      about: "Despre Noi",
      services: "Servicii",
      calculator: "Calculator",
      contact: "Contact"
    },
    hero: {
      badge: "Partner Oficial al Brokerului de Asigurări",
      title: "Transformă Asigurările în",
      titleHighlight: "Rate GRATUIT",
      description: "Serviciu de factoring complet gratuit pentru tine. Toate costurile sunt acoperite de brokerul nostru partener.",
      phonePlaceholder: "Introduceți numărul de telefon",
      callButton: "Te Sunăm Noi",
      successMessage: "Vă vom contacta în curând!"
    },
    benefits: [
      "Aprobare în maxim 2 ore",
      "Zero costuri de factoring",
      "Asigurare activă instant"
    ],
    footer: {
      address: "sec. Ciocana, str. Otovasca, 1,\nMD2023 mun. Chişinău",
      phone: "+373 123 45 678",
      email: "contact@rdmfinance.md",
      links: [
        'Despre Noi',
        'Servicii',
        'Calculator',
        'Termeni și Condiții',
        'Politica de Confidențialitate'
      ],
      rights: "© 2024 RDM FINANCE SOLUTIONS. Toate drepturile rezervate."
    }
  },
  ru: {
    nav: {
      about: "О Нас",
      services: "Услуги",
      calculator: "Калькулятор",
      contact: "Контакты"
    },
    hero: {
      badge: "Официальный Партнер Страхового Брокера",
      title: "Оформите Страховку в",
      titleHighlight: "РАССРОЧКУ БЕСПЛАТНО",
      description: "Полностью бесплатный факторинговый сервис для вас. Все расходы покрываются нашим брокером-партнером.",
      phonePlaceholder: "Введите номер телефона",
      callButton: "Мы Вам Перезвоним",
      successMessage: "Мы скоро с вами свяжемся!"
    },
    benefits: [
      "Одобрение максимум за 2 часа",
      "Нулевые затраты на факторинг",
      "Мгновенная активация страховки"
    ],
    footer: {
      address: "сек. Чокана, ул. Отоваска, 1,\nMD2023 мун. Кишинёв",
      phone: "+373 123 45 678",
      email: "contact@rdmfinance.md",
      links: [
        'О Нас',
        'Услуги',
        'Калькулятор',
        'Условия и Положения',
        'Политика Конфиденциальности'
      ],
      rights: "© 2024 RDM FINANCE SOLUTIONS. Все права защищены."
    }
  }
};
